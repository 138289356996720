import client from './client';

export const getAppointmentsApi = params => client().get('/appointments', { params });

export const getPastAppointmentsApi = ({ patientId, currentDate }) =>
  client().get('/appointments', {
    params: {
      patientId,
      'endDateTimestamp[$lt]': currentDate,
    },
  });
export const createAppointmentApi = data => client().post('/appointments', data);

export const appointmentApi = id => client().get(`/appointments/${id}`);

export const updateAppointmentApi = ({ id, ...data }) => client().put(`/appointments/${id}`, data);

export const removeAppointmentApi = ({ id, ...params }) => client().delete(`/appointments/${id}`, { params });
