import { ReactComponent as AccountingInvoiceHand } from './icon-files/streamline-icon-accounting-invoice-hand.svg';
import { ReactComponent as AddBold } from './icon-files/streamline-icon-add-bold.svg';
import { ReactComponent as AddRegular } from './icon-files/streamline-icon-add-regular.svg';
import { ReactComponent as AlarmBellSleep } from './icon-files/streamline-icon-alarm-bell-sleep.svg';
import { ReactComponent as AlertCircle } from './icon-files/streamline-icon-alert-circle.svg';
import { ReactComponent as AlertTriangle } from './icon-files/streamline-icon-alert-triangle.svg';
import { ReactComponent as AnalyticsGraphBar } from './icon-files/streamline-icon-analytics-graph-bar.svg';
import { ReactComponent as ArrowBottom } from './icon-files/streamline-icon-arrow-bottom.svg';
import { ReactComponent as ArrowDown } from './icon-files/streamline-icon-arrow-down.svg';
import { ReactComponent as ArrowLeft1 } from './icon-files/streamline-icon-arrow-left-1.svg';
import { ReactComponent as ArrowRight } from './icon-files/streamline-icon-arrow-right.svg';
import { ReactComponent as ArrowThickCircleDown } from './icon-files/streamline-icon-arrow-thick-circle-down.svg';
import { ReactComponent as ArrowThickCircleUp } from './icon-files/streamline-icon-arrow-thick-circle-up.svg';
import { ReactComponent as ArrowUpLong } from './icon-files/streamline-icon-arrow-up-long.svg';
import { ReactComponent as ArrowUp } from './icon-files/streamline-icon-arrow-up.svg';
import { ReactComponent as AsianFoodNoodlesBowl } from './icon-files/streamline-icon-asian-food-noodles-bowl.svg';
import { ReactComponent as Attachment } from './icon-files/streamline-icon-attachment.svg';
import { ReactComponent as AudioBookArt } from './icon-files/streamline-icon-audio-book-art.svg';
import { ReactComponent as AwardTrophyStar } from './icon-files/streamline-icon-award-trophy-star.svg';
import { ReactComponent as Bin1 } from './icon-files/streamline-icon-bin-1.svg';
import { ReactComponent as BookReadHuman } from './icon-files/streamline-icon-book-read-human.svg';
import { ReactComponent as Bulb } from './icon-files/streamline-icon-bulb.svg';
import { ReactComponent as Calendar } from './icon-files/streamline-icon-calendar.svg';
import { ReactComponent as Check1Bold } from './icon-files/streamline-icon-check-1-bold.svg';
import { ReactComponent as Check1 } from './icon-files/streamline-icon-check-1.svg';
import { ReactComponent as CheckCircle1Stroked } from './icon-files/streamline-icon-check-circle-1-stroked.svg';
import { ReactComponent as CheckCircle1 } from './icon-files/streamline-icon-check-circle-1.svg';
import { ReactComponent as CheckShield } from './icon-files/streamline-icon-check-shield.svg';
import { ReactComponent as ChefGearPizza } from './icon-files/streamline-icon-chef-gear-pizza.svg';
import { ReactComponent as CogDouble2 } from './icon-files/streamline-icon-cog-double-2.svg';
import { ReactComponent as Cog } from './icon-files/streamline-icon-cog.svg';
import { ReactComponent as ColorPaintingPalette } from './icon-files/streamline-icon-color-painting-palette.svg';
import { ReactComponent as CommonFileQuill } from './icon-files/streamline-icon-common-file-quill.svg';
import { ReactComponent as ContentNotebookPencil } from './icon-files/streamline-icon-content-notebook-pencil.svg';
import { ReactComponent as ContentWrite } from './icon-files/streamline-icon-content-write.svg';
import { ReactComponent as CopyPaste } from './icon-files/streamline-icon-copy-paste.svg';
import { ReactComponent as CursorTarget1 } from './icon-files/streamline-icon-cursor-target-1.svg';
import { ReactComponent as DataFileBarsUpload } from './icon-files/streamline-icon-data-file-bars-upload.svg';
import { ReactComponent as DatingTarget1 } from './icon-files/streamline-icon-dating-target-1.svg';
import { ReactComponent as Delete2 } from './icon-files/streamline-icon-delete-2.svg';
import { ReactComponent as DietScale } from './icon-files/streamline-icon-diet-scale.svg';
import { ReactComponent as DonationCharityHandCareHeart } from './icon-files/streamline-icon-donation-charity-hand-care-heart.svg';
import { ReactComponent as DownloadCircle } from './icon-files/streamline-icon-download-circle.svg';
import { ReactComponent as EarthEdit } from './icon-files/streamline-icon-earth-edit.svg';
import { ReactComponent as ElectronicsIntegratedCircuit } from './icon-files/streamline-icon-electronics-integrated-circuit.svg';
import { ReactComponent as Envelope } from './icon-files/streamline-icon-envelope.svg';
import { ReactComponent as EvolveWhite } from './icon-files/streamline-icon-evolve-white.svg';
import { ReactComponent as Expand3 } from './icon-files/streamline-icon-expand-3.svg';
import { ReactComponent as Filter1 } from './icon-files/streamline-icon-filter-1.svg';
import { ReactComponent as FilterText } from './icon-files/streamline-icon-filter-text.svg';
import { ReactComponent as TextractError } from './icon-files/streamline-icon-textract-error.svg';
import { ReactComponent as TextractLookupMaster } from './icon-files/streamline-icon-textract-lookup-master.svg';
import { ReactComponent as FitnessDumbbell } from './icon-files/streamline-icon-fitness-dumbbell.svg';
import { ReactComponent as FolderCode } from './icon-files/streamline-icon-folder-code.svg';
import { ReactComponent as FolderFile1 } from './icon-files/streamline-icon-folder-file-1.svg';
import { ReactComponent as FoodDeliveryFoodBillHistoryTicket } from './icon-files/streamline-icon-food-delivery-food-bill-history-ticket.svg';
import { ReactComponent as FruitApple } from './icon-files/streamline-icon-fruit-apple.svg';
import { ReactComponent as GoDeep } from './icon-files/streamline-icon-go-deep.svg';
import { ReactComponent as GreekBuilding } from './icon-files/streamline-icon-greek-building.svg';
import { ReactComponent as HospitalBuilding1 } from './icon-files/streamline-icon-hospital-building-1.svg';
import { ReactComponent as HospitalBuilding2 } from './icon-files/streamline-icon-hospital-building-2.svg';
import { ReactComponent as HospitalFirstAid } from './icon-files/streamline-icon-hospital-first-aid.svg';
import { ReactComponent as HotelDoubleBed1 } from './icon-files/streamline-icon-hotel-double-bed-1.svg';
import { ReactComponent as House1 } from './icon-files/streamline-icon-house-1.svg';
import { ReactComponent as HumanResourcesWorkflow } from './icon-files/streamline-icon-human-resources-workflow.svg';
import { ReactComponent as InformationCircleBold } from './icon-files/streamline-icon-information-circle-bold.svg';
import { ReactComponent as InformationCircle } from './icon-files/streamline-icon-information-circle.svg';
import { ReactComponent as InsuranceHands } from './icon-files/streamline-icon-insurance-hands.svg';
import { ReactComponent as InterfaceEdit } from './icon-files/streamline-icon-interface-edit.svg';
import { ReactComponent as InterfaceFileMultiple } from './icon-files/streamline-icon-interface-file-multiple.svg';
import { ReactComponent as InterfaceTimer } from './icon-files/streamline-icon-interface-timer.svg';
import { ReactComponent as KeyboardArrowDown } from './icon-files/streamline-icon-keyboard-arrow-down.svg';
import { ReactComponent as KeyboardArrowUp } from './icon-files/streamline-icon-keyboard-arrow-up.svg';
import { ReactComponent as ListQuill } from './icon-files/streamline-icon-list-quill.svg';
import { ReactComponent as Logout } from './icon-files/streamline-icon-logout.svg';
import { ReactComponent as LoveItCheck } from './icon-files/streamline-icon-love-it-check.svg';
import { ReactComponent as MailboxHouse } from './icon-files/streamline-icon-mailbox-house.svg';
import { ReactComponent as MedicalAppLaptop } from './icon-files/streamline-icon-medical-app-laptop.svg';
import { ReactComponent as MedicalFolder } from './icon-files/streamline-icon-medical-folder.svg';
import { ReactComponent as MeetingCameraSquare } from './icon-files/streamline-icon-meeting-camera-square.svg';
import { ReactComponent as MeetingCamera } from './icon-files/streamline-icon-meeting-camera.svg';
import { ReactComponent as MessagesBubbleForwardAll } from './icon-files/streamline-icon-messages-bubble-forward-all.svg';
import { ReactComponent as MessagesBubbleSquare } from './icon-files/streamline-icon-messages-bubble-square.svg';
import { ReactComponent as Microphone } from './icon-files/streamline-icon-microphone.svg';
import { ReactComponent as MonetizationUserLaptop } from './icon-files/streamline-icon-monetization-user-laptop.svg';
import { ReactComponent as Monitor1 } from './icon-files/streamline-icon-monitor-1.svg';
import { ReactComponent as MonitorHeartBeatSearch } from './icon-files/streamline-icon-monitor-heart-beat-search.svg';
import { ReactComponent as MultipleNeutral2 } from './icon-files/streamline-icon-multiple-neutral-2.svg';
import { ReactComponent as MultipleUsers2 } from './icon-files/streamline-icon-multiple-users-2.svg';
import { ReactComponent as MultipleUsers3 } from './icon-files/streamline-icon-multiple-users-3.svg';
import { ReactComponent as NavigationMenuVertical } from './icon-files/streamline-icon-navigation-menu-vertical.svg';
import { ReactComponent as NavigationMenu } from './icon-files/streamline-icon-navigation-menu.svg';
import { ReactComponent as OnlineLearningOnlineCourse2 } from './icon-files/streamline-icon-online-learning-online-course-2.svg';
import { ReactComponent as OnlineLearningStudent4 } from './icon-files/streamline-icon-online-learning-student-4.svg';
import { ReactComponent as PatientExtract } from './icon-files/streamline-icon-patient-extract.svg';
import { ReactComponent as PatientProtection } from './icon-files/streamline-icon-patient-protection.svg';
import { ReactComponent as Pencil } from './icon-files/streamline-icon-pencil.svg';
import { ReactComponent as Pill } from './icon-files/streamline-icon-pill.svg';
import { ReactComponent as ProfessionsManDoctor2 } from './icon-files/streamline-icon-professions-man-doctor-2.svg';
import { ReactComponent as ReadHuman } from './icon-files/streamline-icon-read-human.svg';
import { ReactComponent as RealEstateActionBuildingHeart } from './icon-files/streamline-icon-real-estate-action-building-heart.svg';
import { ReactComponent as RealEstateDimensionsBuildingRuler } from './icon-files/streamline-icon-real-estate-dimensions-building-ruler.svg';
import { ReactComponent as RestaurantEatingSet } from './icon-files/streamline-icon-restaurant-eating-set.svg';
import { ReactComponent as RomancePrideLesbianLgbtGlobalGlobe } from './icon-files/streamline-icon-romance-pride-lesbian-lgbt-global-globe.svg';
import { ReactComponent as RotateBack } from './icon-files/streamline-icon-rotate-back.svg';
import { ReactComponent as RpmNavigation } from './icon-files/streamline-icon-rpm-navigation.svg';
import { ReactComponent as Search } from './icon-files/streamline-icon-search.svg';
import { ReactComponent as ServerClock } from './icon-files/streamline-icon-server-clock.svg';
import { ReactComponent as SettingsOff } from './icon-files/streamline-icon-settings-off.svg';
import { ReactComponent as SettingsOn } from './icon-files/streamline-icon-settings-on.svg';
import { ReactComponent as ShoppingDirectMailAdvertisingEnvelopeMegaphone } from './icon-files/streamline-icon-shopping-direct-mail-advertising-envelope-megaphone.svg';
import { ReactComponent as SingleNeutralActionsAdd } from './icon-files/streamline-icon-single-neutral-actions-add.svg';
import { ReactComponent as SingleNeutralBook } from './icon-files/streamline-icon-single-neutral-book.svg';
import { ReactComponent as SingleNeutralCircle } from './icon-files/streamline-icon-single-neutral-circle.svg';
import { ReactComponent as StairsPersonDescend } from './icon-files/streamline-icon-stairs-person-descend.svg';
import { ReactComponent as StudyVirtualClassroom2 } from './icon-files/streamline-icon-study-virtual-classroom-2.svg';
import { ReactComponent as Subtract } from './icon-files/streamline-icon-subtract.svg';
import { ReactComponent as SynchronizeArrows1 } from './icon-files/streamline-icon-synchronize-arrows-1.svg';
import { ReactComponent as TargetCenter1 } from './icon-files/streamline-icon-target-center-1.svg';
import { ReactComponent as TaskListPen } from './icon-files/streamline-icon-task-list-pen.svg';
import { ReactComponent as Telephone } from './icon-files/streamline-icon-telephone.svg';
import { ReactComponent as ThickCheck } from './icon-files/streamline-icon-thick-check.svg';
import { ReactComponent as TimeClockCircle1 } from './icon-files/streamline-icon-time-clock-circle-1.svg';
import { ReactComponent as TimeExerciseTime2 } from './icon-files/streamline-icon-time-exercise-time-2.svg';
import { ReactComponent as TimeMonthly1 } from './icon-files/streamline-icon-time-monthly-1.svg';
import { ReactComponent as Translate } from './icon-files/streamline-icon-translate.svg';
import { ReactComponent as TravelInsuranceCover } from './icon-files/streamline-icon-travel-insurance-cover.svg';
import { ReactComponent as UiBrowserSlider } from './icon-files/streamline-icon-ui-browser-slider.svg';
import { ReactComponent as UploadCircle } from './icon-files/streamline-icon-upload-circle.svg';
import { ReactComponent as UserFemaleTeacherMath } from './icon-files/streamline-icon-user-female-teacher-math.svg';
import { ReactComponent as TimerStop } from './icon-files/streamline-icon-timer-stop.svg';
import { ReactComponent as TimerPlay } from './icon-files/streamline-icon-timer-play.svg';
import { ReactComponent as Graph } from './icon-files/streamline-icon-graph.svg';
import { ReactComponent as NormalHuman } from './icon-files/streamline-icon-normal-human.svg';
import { ReactComponent as ProviderStethoscope } from './icon-files/streamline-icon-provider-stethoscope.svg';

const streamlineSVGIcons = {
  'accounting-invoice-hand': AccountingInvoiceHand,
  'add-bold': AddBold,
  'add-regular': AddRegular,
  'alarm-bell-sleep': AlarmBellSleep,
  'alert-circle': AlertCircle,
  'alert-triangle': AlertTriangle,
  'analytics-graph-bar': AnalyticsGraphBar,
  'arrow-bottom': ArrowBottom,
  'arrow-down': ArrowDown,
  'arrow-left-1': ArrowLeft1,
  'arrow-right': ArrowRight,
  'arrow-thick-circle-down': ArrowThickCircleDown,
  'arrow-thick-circle-up': ArrowThickCircleUp,
  'arrow-up-long': ArrowUpLong,
  'arrow-up': ArrowUp,
  'asian-food-noodles-bowl': AsianFoodNoodlesBowl,
  attachment: Attachment,
  'audio-book-art': AudioBookArt,
  'award-trophy-star': AwardTrophyStar,
  'bin-1': Bin1,
  'book-read-human': BookReadHuman,
  bulb: Bulb,
  calendar: Calendar,
  'check-1-bold': Check1Bold,
  'check-1': Check1,
  'check-circle-1-stroked': CheckCircle1Stroked,
  'check-circle-1': CheckCircle1,
  'check-shield': CheckShield,
  'chef-gear-pizza': ChefGearPizza,
  'cog-double-2': CogDouble2,
  cog: Cog,
  'color-painting-palette': ColorPaintingPalette,
  'common-file-quill': CommonFileQuill,
  'content-notebook-pencil': ContentNotebookPencil,
  'content-write': ContentWrite,
  'copy-paste': CopyPaste,
  'cursor-target-1': CursorTarget1,
  'data-file-bars-upload': DataFileBarsUpload,
  'dating-target-1': DatingTarget1,
  'delete-2': Delete2,
  'diet-scale': DietScale,
  'donation-charity-hand-care-heart': DonationCharityHandCareHeart,
  'download-circle': DownloadCircle,
  'earth-edit': EarthEdit,
  'electronics-integrated-circuit': ElectronicsIntegratedCircuit,
  envelope: Envelope,
  'evolve-white': EvolveWhite,
  'expand-3': Expand3,
  'filter-1': Filter1,
  'filter-text': FilterText,
  'textract-error': TextractError,
  'textract-lookup-master': TextractLookupMaster,
  'fitness-dumbbell': FitnessDumbbell,
  'folder-code': FolderCode,
  'folder-file-1': FolderFile1,
  'food-delivery-food-bill-history-ticket': FoodDeliveryFoodBillHistoryTicket,
  'fruit-apple': FruitApple,
  'go-deep': GoDeep,
  'greek-building': GreekBuilding,
  'hospital-building-1': HospitalBuilding1,
  'hospital-building-2': HospitalBuilding2,
  'hospital-first-aid': HospitalFirstAid,
  'hotel-double-bed-1': HotelDoubleBed1,
  'house-1': House1,
  'human-resources-workflow': HumanResourcesWorkflow,
  'information-circle-bold': InformationCircleBold,
  'information-circle': InformationCircle,
  'insurance-hands': InsuranceHands,
  'interface-edit': InterfaceEdit,
  'interface-file-multiple': InterfaceFileMultiple,
  'interface-timer': InterfaceTimer,
  'keyboard-arrow-down': KeyboardArrowDown,
  'keyboard-arrow-up': KeyboardArrowUp,
  'list-quill': ListQuill,
  logout: Logout,
  'love-it-check': LoveItCheck,
  'mailbox-house': MailboxHouse,
  'medical-app-laptop': MedicalAppLaptop,
  'medical-folder': MedicalFolder,
  'meeting-camera-square': MeetingCameraSquare,
  'meeting-camera': MeetingCamera,
  'messages-bubble-forward-all': MessagesBubbleForwardAll,
  'messages-bubble-square': MessagesBubbleSquare,
  microphone: Microphone,
  'monetization-user-laptop': MonetizationUserLaptop,
  'monitor-1': Monitor1,
  'monitor-heart-beat-search': MonitorHeartBeatSearch,
  'multiple-neutral-2': MultipleNeutral2,
  'multiple-users-2': MultipleUsers2,
  'multiple-users-3': MultipleUsers3,
  'navigation-menu-vertical': NavigationMenuVertical,
  'navigation-menu': NavigationMenu,
  'online-learning-online-course-2': OnlineLearningOnlineCourse2,
  'online-learning-student-4': OnlineLearningStudent4,
  'patient-extract': PatientExtract,
  'patient-protection': PatientProtection,
  pencil: Pencil,
  pill: Pill,
  'professions-man-doctor-2': ProfessionsManDoctor2,
  'read-human': ReadHuman,
  'real-estate-action-building-heart': RealEstateActionBuildingHeart,
  'real-estate-dimensions-building-ruler': RealEstateDimensionsBuildingRuler,
  'restaurant-eating-set': RestaurantEatingSet,
  'romance-pride-lesbian-lgbt-global-globe': RomancePrideLesbianLgbtGlobalGlobe,
  'rotate-back': RotateBack,
  'rpm-navigation': RpmNavigation,
  search: Search,
  'server-clock': ServerClock,
  'settings-off': SettingsOff,
  'settings-on': SettingsOn,
  'shopping-direct-mail-advertising-envelope-megaphone': ShoppingDirectMailAdvertisingEnvelopeMegaphone,
  'single-neutral-actions-add': SingleNeutralActionsAdd,
  'single-neutral-book': SingleNeutralBook,
  'single-neutral-circle': SingleNeutralCircle,
  'stairs-person-descend': StairsPersonDescend,
  'study-virtual-classroom-2': StudyVirtualClassroom2,
  subtract: Subtract,
  'synchronize-arrows-1': SynchronizeArrows1,
  'target-center-1': TargetCenter1,
  'task-list-pen': TaskListPen,
  telephone: Telephone,
  'thick-check': ThickCheck,
  'time-clock-circle-1': TimeClockCircle1,
  'time-exercise-time-2': TimeExerciseTime2,
  'time-monthly-1': TimeMonthly1,
  translate: Translate,
  'travel-insurance-cover': TravelInsuranceCover,
  'ui-browser-slider': UiBrowserSlider,
  'upload-circle': UploadCircle,
  'user-female-teacher-math': UserFemaleTeacherMath,
  'timer-stop': TimerStop,
  'timer-play': TimerPlay,
  'graph-icon': Graph,
  'normal-human': NormalHuman,
  'provider-stethoscope': ProviderStethoscope,
};

export default streamlineSVGIcons;
