import client from './client';

export const createPatientApi = data => client().post('/patients', data);

export const getPatientApi = ({ id, ...params }) => client().get(`/patients/${id}`, { params });

export const getPatientsApi = params => client().get('/patients', { params });

export const updatePatientApi = ({ id, ...data }) => client().put(`/patients/${id}`, data);

export const updatePatientAccountInfoStatusApi = ({ id, ...data }) => client().patch(`/patients/${id}`, data);

export const removePatientApi = ({ id, ...params }) => client().delete(`/patients/${id}`, { params });
