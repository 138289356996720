const mime = require('mime-types');

const FILE_EXTENSIONS = ['.png', '.jpg', '.pdf', '.docx', '.xsl', 'heic', 'heif'];
// image prefix was needed for jpeg to work properly
const WEB_IMAGE_EXTENSIONS = ['image/png', 'image/jpg', 'image/jpeg'];
const EXTENSIONS_MAPPER = FILE_EXTENSIONS.reduce(
  (map, extension) => ({
    ...map,
    [extension]: mime.lookup(extension),
  }),
  {},
);

module.exports = {
  EXTENSIONS_MAPPER,
  WEB_IMAGE_EXTENSIONS,
  FILE_EXTENSIONS,
};
