const settings = {
  clinic: 'clinic',
  virtual: 'virtual',
  zoom: 'zoom',
};

const participantTypes = {
  PATIENT: 'patient',
  PROVIDER: 'provider',
  PROVIDER_ADDITIONAL: 'provider_additional',
};

const appointmentStatuses = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  NO_SHOW: 'no_show',
  RESCHEDULED: 'rescheduled',
  REMOVED: 'removed',
};

const cancellationReasons = [
  {
    id: 1,
    name: 'No Show',
  },
  {
    id: 2,
    name: 'Late cancellation',
  },
  {
    id: 3,
    name: 'Patient conflict',
  },
  {
    id: 4,
    name: 'Provider conflict',
  },
];
const providerRoles = {
  DIETITIAN: 'Dietitian',
};

const visitProviderTypeConstants = {
  MEDICAL: 'Medical',
  NUTRITIONAL: 'Nutritional',
};

const visitPatientTypeConstants = {
  FIRST: 'First',
  FOLLOW: 'Follow',
};

const appointmentNameConstants = {
  NEW_PATIENT: 'NEW',
  FOLLOW_UP: 'FOLLOW',
};

const genAthenaApptTypeName = (apptTypeName, orgId) => {
  if (!apptTypeName) {
    return '';
  }
  const suffix = `-${orgId}`;
  return apptTypeName.length + suffix.length <= 30 ? `${apptTypeName}${suffix}` : `${apptTypeName.substr(0, 30 - suffix.length)}${suffix}`;
};

const appointmentDayOffset = 2;

module.exports = {
  settings,
  participantTypes,
  appointmentStatuses,
  genAthenaApptTypeName,
  cancellationReasons,
  appointmentDayOffset,
  providerRoles,
  visitProviderTypeConstants,
  visitPatientTypeConstants,
  appointmentNameConstants,
};
