module.exports = {
  DEDU_DATA_FETCH_COMMAND: {
    name: 'DeduDataFetch',
    description: 'Fetch Dedu data',
    frequency: '0 9 * * *',
  },
  DEDU_ENROLLMENT_FETCH_COMMAND: {
    name: 'DeduEnrollmentFetch',
    description: 'Fetch Dedu Enrollment data',
    frequency: '*/15 * * * *',
  },
  EMAIL_COMMAND: {
    name: 'Email',
    description: 'Send email',
    frequency: '* * * * *',
  },
  EMAIL_TRACKING_UPDATE_COMMAND: {
    name: 'EmailTrackingUpdate',
    description: 'Update email tracking',
    frequency: '0 19 * * * ',
  },
  FDA_DOWNLOAD_COMMAND: {
    name: 'FDADownload',
    description: 'Download FDA data',
    frequency: 'Manual',
  },
  GOAL_EXPIRATION_COMMAND: {
    name: 'GoalExpiration',
    description: 'Check goal expiration',
    frequency: '* * * * *',
  },
  GOAL_STATUS_COMMAND: {
    name: 'GoalStatus',
    description: 'Check goal status',
    frequency: '0 0 * * *',
  },
  MESSAGE_COMMAND: {
    name: 'Message',
    description: 'Check for unread messages',
    frequency: '0 * * * *',
  },
  PATIENT_STATUS_COMMAND: {
    name: 'PatientStatus',
    description: 'Check patient statuses',
    frequency: '0 8 * * *',
  },
  RECOMMENDATIONS_COMMAND: {
    name: 'Recommendations',
    description: 'Re-check recommendations',
    frequency: '*/5 * * * *',
  },
  USER_REMINDER_COMMAND: {
    name: 'UserReminder',
    description: 'Check user-reminder emails',
    frequency: '0 16 * * *',
  },
  WEIGHT_REMINDER_COMMAND: {
    name: 'WeightReminder',
    description: 'Check weight-reminder emails',
    frequency: '30 15 * * *',
  },
  FOOD_REMINDER_COMMAND: {
    name: 'FoodReminder',
    description: 'Check food-reminder emails',
    frequency: '35 15 * * *',
  },
  COMPLETE_GOAL_REMINDER_COMMAND: {
    name: 'CompleteGoalReminders',
    description: 'Check complete goals emails',
    frequency: '40 15 * * *',
  },
  CREATE_GOAL_REMINDER_COMMAND: {
    name: 'CreateGoalReminders',
    description: 'Check create goals emails',
    frequency: '45 15 * * *',
  },
  QUESTIONNAIRE_REMINDER_COMMAND: {
    name: 'QuestionnaireReminders',
    description: 'Check questionnaire-reminder emails',
    frequency: '50 15 * * *',
  },
  PATIENT_WEEK_REMINDER_COMMAND: {
    name: 'PatientWeekReminders',
    description: 'Check patient week reminder emails',
    frequency: '55 15 * * *',
  },
  GOOGLE_FIT_COMMAND: {
    name: 'GoogleFit',
    description: 'Fetch data from Google fit api',
    frequency: '*/5 * * * *',
  },
  CLEAN_BLOCKED_TOKENS: {
    name: 'CleanBlockedTokens',
    description: 'Clean expired jwt tokens',
    frequency: '0 8 * * *',
  },
  RPM_BILLING_COMMAND: {
    name: 'RPMBilling',
    description: 'Detect whether RPM Readings Threshold is met',
    frequency: '0 7 * * *',
  },
  FETCH_MOBILE_CONTENT_COMMAND: {
    name: 'FetchMobileContent',
    description: 'Fetch mobile content from Google Sheets API',
    frequency: 'Manual',
  },
  DAILY_CHECK_IN_REMINDER_COMMAND: {
    name: 'DailyCheckInReminder',
    commandPrefix: 'mobile-push-notifications/',
    description: 'Send a daily check-in reminder push notification to patients',
    frequency: '55 23 * * *', // 00:00 UTC corresponds to 4 PM PT
  },
  WEEKLY_SUMMARY_COMMAND: {
    name: 'WeeklySummary',
    commandPrefix: 'mobile-push-notifications/',
    description: 'Send weekly summary push notification to patients',
    frequency: '0 20 * * 4', // 20:00 UTC corresponds to 12 PM PT
  },
  WEEKLY_QUIZ_COMMAND: {
    name: 'WeeklyQuiz',
    commandPrefix: 'mobile-push-notifications/',
    description: 'Send quiz summary push notification to patients',
    frequency: '0 18 * * 2', // 18:00 UTC corresponds to 10 AM PT
  },
  EHR_SYNC_COMMAND: {
    name: 'EhrSync',
    description: 'Sync EHR users data ',
    frequency: '*/5 5-9 * * 4',
  },
  PATIENT_READINGS_COMMAND: {
    name: 'PatientReadingsReminder',
    description: 'Send patient readings to provider',
    frequency: '0 16 * * 1',
  },
  DEVICE_USAGE_REMINDER_COMMAND: {
    name: 'DeviceUsageReminder',
    description: 'Send device usage reminder to all patients (RPM not matters)',
    frequency: '0 5 * * 1,4',
  },
  BIRTHDAY_PUSH_NOTIFICATION: {
    name: 'BirthdayWish',
    commandPrefix: 'mobile-push-notifications/',
    description: 'Send birthday wish push notification to patients',
    frequency: '0 17 * * *', // 17:00 UTC corresponds to 9 AM PT
  },
  UPCOMING_APPOINTMENTS_REMINDER_COMMAND: {
    name: 'UpcomingAppointmentsReminder',
    description: 'send reminder to patients for upcoming appointments',
    frequency: '*/5 * * * *',
  },
  NEW_LEARNING_PUSH_NOTIFICATION: {
    name: 'NewLearningMaterial',
    commandPrefix: 'mobile-push-notifications/',
    description: 'Send new learning material push notification to patients',
    frequency: '0 18 * * 6', // 18:00 UTC corresponds to 10 AM PT, 6 corresponds to Saturday
  },
  MISSING_EHR_IDS_SYNC_COMMAND: {
    name: 'MissingEhrIdsSync',
    description: 'Add missing EHR ids for CWCC patients',
    frequency: 'Manual',
  },
  ORG_TO_ATHENA_SYNC: {
    name: 'SyncOrganizationDataToAthena',
    description: 'Synchronize Evolve organization data to Athena',
    frequency: 'Manual', // temporary changed to manual
  },
  CREATE_MEDICATION_REMINDERS_COMMAND: {
    name: 'CreateMedicationReminders',
    description: 'Create Medication reminders',
    frequency: '*/5 * * * *',
  },
  SEND_MEDICATION_REMINDERS_COMMAND: {
    name: 'SendMedicationReminders',
    description: 'Send Medication reminders',
    frequency: '* * * * *',
  },
  ATHENA_MEDICATIONS_CHANGED_SUBSCRIPTION: {
    name: 'AthenaMedicationsChangedSubscription',
    description: 'Establishes new medications changed subscription for Athena',
    frequency: 'Manual',
  },
  ATHENA_MEDICATIONS_CHANGED_SYNC: {
    name: 'AthenaMedicationsChangedSync',
    description: 'Retrieves data from Athena for any patients with medication changes',
    frequency: '*/5 * * * *',
  },
  ATHENA_PATIENT_VITALS_SYNC: {
    name: 'AthenaPatientVitalsSync',
    description: 'Syncs patient-vitals to Athena',
    frequency: '*/5 * * * *',
  },
  ATHENA_ALLERGY_CHANGED_SUBSCRIPTION: {
    name: 'AthenaAllergyChangedSubscription',
    description: 'Establishes new allergy changed subscription for Athena',
    frequency: 'Manual',
  },
  ATHENA_ALLERGY_CHANGED_SYNC: {
    name: 'AthenaAllergyChangedSync',
    description: 'Retrieves data from Athena for any patients with allergy changes',
    frequency: '*/5 * * * *',
  },
  ATHENA_DIAGNOSIS_CHANGED_SUBSCRIPTION: {
    name: 'AthenaDiagnosisChangedSubscription',
    description: 'Establishes new diagnosis changed subscription for Athena',
    frequency: 'Manual',
  },
  ATHENA_DIAGNOSIS_CHANGED_SYNC: {
    name: 'AthenaDiagnosisChangedSync',
    description: 'Retrieves data from Athena for any patients with diagnosis changes',
    frequency: '*/5 * * * *',
  },
  ATHENA_CHECKLIST_FILES_SYNC: {
    name: 'AthenaChecklistFilesSync',
    description: 'Command to sync patient uploaded files to Athena',
    frequency: '*/15 * * * *',
  },
  CREATE_ATHENA_APPOINTMENTS: {
    name: 'CreateAthenaAppointments',
    description: 'Creates appointments in Athena EHR for any un-synced appointments',
    frequency: '0 22 * * *',
  },
  DEACTIVATE_EXPIRED_MEDICATION_REMINDERS: {
    name: 'DeactivateExpiredMedicationReminders',
    description: 'Deactivates medication reminders that have AOMs expiring on the current day',
    frequency: '0 0 * * *',
  },
  ATHENA_EMERGENCY_CONTACT_SYNC: {
    name: 'AthenaEmergencyContactSync',
    description: 'Synchronizes Emergency Contact data between Evolve and Athena',
    frequency: '0 3 * * *',
  },
  ITERABLE_PATIENT_DATA_SYNC: {
    name: 'IterablePatientDataSync',
    description: 'Syncs Patient Data to Iterable',
    frequency: '0 22 * * *',
  },
  ITERABLE_PROVIDER_DATA_SYNC: {
    name: 'IterableProviderDataSync',
    description: 'Syncs Provider Data to Iterable',
    frequency: '0 22 * * *',
  },
  ITERABLE_APPOINTMENT_TRIGGER: {
    name: 'IterableAppointmentTrigger',
    description: 'Triggers Iterable Appointment event',
    frequency: '30 22 * * *',
  },
  WITHINGS_ORDER_DEVICES: {
    name: 'WithingsOrderDevices',
    description: 'This command allows us to order Withings devices for patients.',
    frequency: 'Manual',
  },
  ATHENA_CHECKLIST_CONSENTS_SYNC: {
    name: 'AthenaChecklistConsentsSync',
    description: 'Command to sync patient consent forms that failed initial upload to Athena',
    frequency: '*/20 * * * *',
  },
  WITHINGS_UPDATE_ORDER_STATUSES: {
    name: 'WithingsUpdateOrderStatuses',
    description: 'This command gets updated order information for shipped Withings Orders',
    frequency: '0 22 * * *',
  },
  ATHENA_SIGNED_OFF_ORDER_SUBSCRIPTION: {
    name: 'AthenaSignedOffOrderSubscription',
    description: 'Establishes new signed off order subscription for Athena',
    frequency: 'Manual',
  },
  OBSERVATION_TO_PATIENT_VITALS: {
    name: 'ObservationToPatientVitals',
    description: 'Sync observation payload to patient vitals',
    frequency: '*/5 * * * *',
  },
  ATHENA_FETCH_WITHINGS_ORDERS: {
    name: 'AthenaCreateWithingsOrdersCommand',
    description: 'Process Athena signed orders for KIT orders to Withings',
    frequency: '15 */1 * * *',
  },
  ATHENA_FETCH_ORDERS: {
    name: 'AthenaFetchOrders',
    description: 'Gets Athena signed off orders and save them to Athena Signed Off API Logs db table',
    frequency: '6 * * * *',
  },
  SYNC_EMPANELMENT_PROVIDER_MAPPINGS: {
    name: 'SyncProviderEmpanelmentMappings',
    description:
      'Pulls data from the google sheet (https://docs.google.com/spreadsheets/d/1ZYN7I9If_MaNjU9pLYa5PZBfAaSGnBT2spbR26_Ish8/edit#gid=0) to map providers to their respective empanelment tables',
    frequency: '40 * * * * ',
  },
  CREATE_ATHENA_INDIVIDUAL_WITHINGS_ORDERS: {
    name: 'CreateAthenaIndividualWithingsOrders',
    description: 'Process Athena signed orders for individual orders to Withings.',
    frequency: '0 * * * *',
  },
  ATHENA_PATIENT_CASE_CHANGED_SUBSCRIPTION: {
    name: 'AthenaPatientCaseChangedSubscription',
    description: 'Establishes patient case changed subscription for Athena',
    frequency: 'Manual',
  },
  ATHENA_PATIENT_CASE_CHANGED_SYNC: {
    name: 'AthenaPatientCaseChangedSync',
    description: 'Retrieves data from Athena for patient case changes',
    frequency: '*/5 * * * *',
  },
};
